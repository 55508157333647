import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Blog = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
          edges {
            node {
              title
              id
              slug
              publishedDate(formatString: "Do MMMM, YYYY")
              featuredImage {
                fluid(maxWidth: 600) {
                  src
                }
              }
              excerpt {
                childMarkdownRemark {
                  excerpt(pruneLength: 150)
                }
              }
            }
          }
        }
        allContentfulBlogPage {
          nodes {
            seoDescription
            title
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO
        title={data.allContentfulBlogPage.nodes[0].title}
        description={data.allContentfulBlogPage.nodes[0].seoDescription}
      />
      <h1>{data.allContentfulBlogPage.nodes[0].title}</h1>
      <div className="px-6 blog-grid">
        {data.allContentfulBlogPost.edges.map(edge => {
          return (
            <div key={edge.node.id} className="my-4">
              {edge.node.featuredImage && (
                <div
                  className="posts py-4 px-2 shadow-light rounded-lg"
                  style={{
                    background: `url('${edge.node.featuredImage.fluid.src}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                  }}
                >
                  <div className="posts">
                    <h3 className="text-left">{edge.node.title}</h3>
                    <p>{edge.node.excerpt.childMarkdownRemark.excerpt}</p>
                    <p className="text-sm date">{edge.node.publishedDate}</p>
                    <button className="button shadow-xl">
                      <Link
                        to={`/blog/${edge.node.slug}/`}
                        title={`Read More on ${edge.node.slug}`}
                      >
                        Read More
                      </Link>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default Blog
